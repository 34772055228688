import axios from "@axios";

export default {
    namespaced: true,
    state: {
        listsAnimalStatus: [],
    },
    getters: {
        getListsAnimalStatus: (state) => state.listsAnimalStatus,
    },
    mutations: {
        SET_LISTS_ANIMAL_STATUS(state, value) {
            state.listsAnimalStatus = value;
        },
    },
    actions: {
        getAnimalStatus(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    .get("/statuses")
                    .then((response) => {
                        resolve(response);
                        ctx.commit(
                            "SET_LISTS_ANIMAL_STATUS",
                            response.data.data
                        );
                    })
                    .catch((error) => reject(error));
            });
        },
        postAnimalStatus(ctx, name) {
            // console.log(name)
            return new Promise((resolve, reject) => {
                axios
                    .post("/statuses", { name })
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        },
        putAnimalStatus(ctx, { id, name }) {
            // console.log(id, name);
            return new Promise((resolve, reject) => {
                axios
                    .put(`/statuses/${id}`, { name })
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        },

        deleteAnimalStatus(ctx, { id }) {
            // console.log(id, name);
            return new Promise((resolve, reject) => {
                axios
                    .delete(`/statuses/${id}`)
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        },
    },
};
